class CopyLinkElement extends HTMLElement {
	constructor() {
		super();

		this.querySelector("button").addEventListener("click", () => {
			const input = this.querySelector("input");
			
			input.select();
			input.setSelectionRange(0, 99999);
			document.execCommand("copy");
		});
	}
}

customElements.define('bsm-copy-link', CopyLinkElement);