//
// Controls for the mailing list signup form
//
class SignupElement extends HTMLElement {
	onsubmit(event) {
		event.preventDefault();

		fetch(`/home/email-signup`, {
			method: "POST",
			body: new FormData(this.form)
		})
		.then(response => {
			if (response.ok) {
				this.form.reset();
			}
		});
	}
	
	connectedCallback() {
		this.form = document.createElement("form");
		this.emailInput = document.createElement("input");
		this.signupButton = document.createElement("button");

		this.emailInput.placeholder = "Email Address";
		this.emailInput.type = "email";
		this.emailInput.name = "emailAddress"
		this.emailInput.required = true;

		this.signupButton.textContent = "Sign Up";
		this.signupButton.type = "submit";

		this.form.onsubmit = event => this.onsubmit(event);
		this.form.append(this.emailInput, this.signupButton);

		this.append(this.form);
	}
}

customElements.define("bsm-signup", SignupElement);