if (document.body.classList.contains("Resources")) {
	document.getElementById("category-select").addEventListener("change", event => {
		let category = event.currentTarget.value;
		let baseUrl = document.querySelector("main").dataset.baseUrl;

		if (category) {
			location.replace(`${baseUrl}filter/${category}`);
		}
		else {
			location.replace(baseUrl);
		}
	});

	let showMoreButton = document.getElementById("show-more");

	if (showMoreButton) {
		showMoreButton.addEventListener("click", () => {
			const resources = document.getElementById("resources");

			fetch(`/resources/load?limit=9&offset=${resources.children.length}`)
			.then(res => res.text())
			.then(html => {
				let parse = document.createElement("div");

				parse.innerHTML += html;

				requestAnimationFrame(() => {
					resources.append(...parse.children);

					if (parseInt(document.querySelector("main").dataset.totalResources) <= resources.children.length) {
						document.getElementById("show-more").remove();
					}
				});
			});
		});
	}
}

if (document.body.classList.contains("Resource")) {
	document.querySelector(".share.copy").addEventListener("click", event => {
		if (navigator.share) {
			navigator.share({
				title: document.title,
				text: document.querySelector("meta[name=description]").content,
				url: location.href
			});
		}
		else {
			let link = document.getElementById("share-link");

			link.classList.remove("hidden");
			link.select();
			document.execCommand("copy");
		}
	});	
}