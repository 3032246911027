const contactForm = document.querySelector("#contact form");

if (contactForm) {
	contactForm.addEventListener("submit", event => {
		event.preventDefault();

		contactForm.setAttribute("locked", "");

		const formData = new FormData(contactForm);

		formData.set("interests", formData.getAll("interests").join("\n"));

		fetch("/contact/submit", {
			method: "POST",
			body: formData
		})
		.then(response => {
			if (response.ok) {
				contactForm.setAttribute("success", "");
			}
			else {
				contactForm.removeAttribute("locked", "");
			}
		});

		return false;
	});
}