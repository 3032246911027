const career = () => {
	let form = document.querySelector("#application form");
	form.scrollIntoView({behavior: "smooth", block: "center", inline: "nearest"});
	// form.querySelector("[name='opening']").value = id;
	form.querySelector("[name='first-name']").focus({ preventScroll: true });
}

if (document.body.classList.contains("Careers")) {
	let form = document.querySelector("#application form");

	form.addEventListener("submit", event => {
		event.preventDefault();

		form.classList.add("locked");
		form.removeAttribute("message", "Sending...");

		let data = new FormData(form);

		fetch(form.action, {	
			method: "POST",
			body: data
		}).then(response => {
			form.setAttribute("message", response.ok ? "Application received!" : "Form failed, please reload the page and try again.");
		});
	});
}