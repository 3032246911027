class DropdownElement extends HTMLElement {
	updateContentSize() {
		this.contentWrapper.style.setProperty("--content-height", this.content.getBoundingClientRect().height + "px");
	}

	toggle() {
		this.classList.toggle("open");
	}

	get open() {
		return this.classList.contains("open");
	}

	set open(open) {
		return this.classList.toggle("open", open);
	}

	 constructor() {
		super();

		let titleSource = this.querySelector("[name=title]");
		let contentSource = this.querySelector("[name=content]");

		this.toggleElement = this.querySelector("[name=toggle]");

		this.titleElement = document.createElement("span");
		this.titleElement.className = "bsm-dropdown--title";
		this.titleElement.tabIndex = 0;
		this.titleElement.append(titleSource.textContent);

		this.titleElement.addEventListener("click", () => {
			this.updateContentSize();
			this.toggle();
		});

		this.titleElement.addEventListener("keypress", event => {
			if (event.key === "Enter") {
				this.updateContentSize();
				this.toggle();
			}
		});

		if (this.toggleElement) {
			this.toggleElement.className = this.toggleElement.className || "bsm-dropdown--toggle";
			this.toggleElement.removeAttribute("name");
			this.titleElement.append(this.toggleElement);
		}

		this.contentWrapper = document.createElement("div");
		this.contentWrapper.className = "bsm-dropdown--content-wrapper";

		this.content = document.createElement("div");
		this.content.className = "bsm-dropdown--content";
		this.content.append(...contentSource.children);

		this.contentWrapper.append(this.content);

		titleSource.remove();
		contentSource.remove();

		this.append(this.titleElement, this.contentWrapper);
	 }
}

customElements.define("bsm-dropdown", DropdownElement);