if (document.body.classList.contains("Research")) {
	const showArticlesButton = document.getElementById("show-articles");

	showArticlesButton?.addEventListener("click", () => {
		const articles = document.getElementById("articles");
		const range = parseInt(articles.dataset.range);
		const newRange = range + 3;
		let articleCount = 0;

		articles.querySelectorAll(".article").forEach((element, index) => {
			element.classList.toggle("show", index < newRange);
			articleCount++;
		});

		articles.dataset.range = newRange.toString();

		showArticlesButton.classList.toggle("hide", articleCount <= newRange);
	});

	for (let event of document.querySelectorAll("#events .event-item .event-title")) {
		event.addEventListener("mouseenter", () => {
			const index = event.dataset.index;

			document.querySelectorAll("#events .event-image.visible")
			.forEach(img => img.classList.remove("visible"));
			
			document.querySelectorAll(`#events .event-image[data-index="${index}"]`)
			.forEach(img => img.classList.add("visible"));
		});
	}
}