/** @type {Element[]} */
const animatedElements = [];

const resolveElements = () => {
	document.querySelectorAll("[scrollable]").forEach(element => {
		animatedElements.push(element);
	});

	document.querySelectorAll("[scrollable-content]").forEach(element => {
		animatedElements.push(element);

		Array.from(element.children).forEach((child, index) => {
			child.style.setProperty("--offset", index);
		});
	});
}

const updateElements = () => {
	for (let element of animatedElements) {
		element.classList.toggle("visible", element.getBoundingClientRect().top < innerHeight * 0.8);
	}
}

addEventListener("scroll", updateElements, { passive: true });
addEventListener("resize", updateElements);
addEventListener("load", () => {
	resolveElements();
	updateElements();
});