class Carousel extends HTMLElement {
	get offset() {
		return Number(this.getAttribute("offset") || 0);
	}

	updateIndices() {
		this.querySelectorAll("[item]").forEach((item, index) => {
			item.style.setProperty("--index", index + this.offset);
			item.setAttribute("index", index + this.offset);
		});
	}

	constructor() {
		super();

		for (let child of this.children) {
			child.setAttribute("item", "");
		}

		let prevButton = document.createElement("button");
		prevButton.setAttribute("button", "prev");
		prevButton.title = "Previous";

		prevButton.onclick = () => {
			this.prepend(Array.from(this.querySelectorAll("[item]")).slice(-1)[0]);
			this.updateIndices();
		}
		
		let nextButton = document.createElement("button");
		nextButton.setAttribute("button", "next");
		nextButton.title = "Next";
		
		nextButton.onclick = () => {
			this.append(Array.from(this.querySelectorAll("[item]")).slice(0)[0]);
			this.updateIndices();
		}

		this.append(prevButton, nextButton);

		this.updateIndices();
	}
}

customElements.define("bsm-carousel", Carousel);